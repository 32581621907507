import * as React from 'react';
import clsx from 'clsx';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import { useInView } from 'react-intersection-observer';
import { useAppContext } from 'contexts/app';
import { HOMEPAGE_BG_COLOR } from 'contexts/app/store';
import View from 'components/View';
import dynamic from 'next/dynamic';

const LottieAnimation = dynamic(() => import('components/LottieAnimation'), {
  ssr: false
});
export interface HomeColorShiftStaticSectionItem {
  title: string;
  subtitle: string;
  id: string;
  animation: any;
  color: HOMEPAGE_BG_COLOR;
  navLabel: string;
  large?: boolean;
  className?: string;
}

const HomeColorShiftSection: React.FC<HomeColorShiftStaticSectionItem> = ({
  title,
  subtitle,
  id,
  animation,
  color,
  large,
  className
}) => {
  const { setHomepageActiveSection, homepageActiveSection } = useAppContext();
  const refTop = React.useRef();
  const refBottom = React.useRef();
  const { ref: inViewRefTop, inView: inViewTop } = useInView({
    rootMargin: '-10px'
  });

  const { ref: inViewRefBottom, inView: inViewBottom } = useInView({
    rootMargin: '10px'
  });

  React.useEffect(() => {
    if (inViewTop && inViewBottom) {
      setHomepageActiveSection({
        color,
        id
      });
    }
  }, [inViewTop, inViewBottom, setHomepageActiveSection, id, color]);

  const setTopRefs = React.useCallback(
    node => {
      refTop.current = node;
      inViewRefTop(node);
    },
    [inViewRefTop]
  );

  const setBottomRefs = React.useCallback(
    node => {
      refBottom.current = node;
      inViewRefBottom(node);
    },
    [inViewRefBottom]
  );

  return (
    <div
      id={id.toLowerCase()}
      className={clsx('HomeColorShiftSection pt-20 min-h-screen flex items-center', className)}
    >
      <View className="w-full sm:w-[650px] text-left md:text-center mx-auto">
        <div ref={setTopRefs} />
        <Heading
          className={clsx(
            'whitespace-pre-line !leading-[1.2] md:!text-[48px] transition-all duration-500 ease-in-out text-2xl',
            {
              ['text-white']: homepageActiveSection.color === HOMEPAGE_BG_COLOR.DARK
            }
          )}
          variant="h2"
        >
          {title}
        </Heading>
        <Paragraph
          className={clsx('mt-2 md:mt-4 text-lg transition-all duration-500 ease-in-out', {
            ['text-white']: homepageActiveSection.color === HOMEPAGE_BG_COLOR.DARK
          })}
          variant="body3"
        >
          {subtitle}
        </Paragraph>
        <div ref={setBottomRefs} />
        <LottieAnimation
          animationName={animation}
          loop={true}
          className={clsx('max-w-[500px] mx-auto mt-6 sm:mt-10', { ['max-w-[600px]']: large })}
        />
      </View>
    </div>
  );
};

export default HomeColorShiftSection;
