import * as React from 'react';
import clsx from 'clsx';
import { useHomeColorShiftStaticSections } from '../HomeColorShiftStaticSections.utils';
import Button from 'components/Button';
import { useAppContext } from 'contexts/app';
import { HOMEPAGE_BG_COLOR } from 'contexts/app/store';
import { HomeColorShiftStaticSectionItem } from '../HomeColorShiftSection/HomeColorShiftSection';

const HomeStickyNavigation: React.FC = () => {
  const items = useHomeColorShiftStaticSections();
  const { homepageActiveSection } = useAppContext();

  const isDark = homepageActiveSection.color === HOMEPAGE_BG_COLOR.DARK;
  const getIsSelected = (item: HomeColorShiftStaticSectionItem) =>
    item.id === homepageActiveSection.id;

  const getButtonVariant = (item: HomeColorShiftStaticSectionItem) => {
    if (isDark) {
      return 'secondary-badge';
    }
    return 'primary-badge';
  };

  return (
    <div
      className={clsx(
        'HomeStickyNavigation sticky top-1/2 -translate-y-1/2 flex flex-col w-fit gap-2 ml-12'
      )}
    >
      {items.map(item => (
        <Button
          className={clsx(
            // default menu is light
            'block w-fit backdrop-blur-md !font-normal',
            {
              // current section is dark => menu is dark
              ['!bg-white/20 hover:!bg-white/30']: isDark,
              // current section is dark and selected => meny item is lighter
              ['!bg-white/40']: isDark && getIsSelected(item),
              // current section is light and selected => meny item is darker
              ['!bg-gray-200']: !isDark && getIsSelected(item)
            }
          )}
          size="badge"
          key={`sticky-navigation-${item.id}`}
          href={`#${item.id.toLocaleLowerCase()}`}
          variant={getButtonVariant(item)}
        >
          {item.navLabel}
        </Button>
      ))}
    </div>
  );
};

export default HomeStickyNavigation;
