import { useTranslation } from 'next-i18next';
import { HomeColorShiftStaticSectionItem } from './HomeColorShiftSection/HomeColorShiftSection';
import { HOMEPAGE_BG_COLOR, HOMEPAGE_SECTION_ID } from 'contexts/app/store';
import { FIREBASE_STORAGE_FILE_PATH } from 'utils/firebase/storage';

export const useHomeColorShiftStaticSections = (): Array<HomeColorShiftStaticSectionItem> => {
  const { t } = useTranslation();

  return [
    {
      navLabel: t('home.colorShift.sections.always'),
      title: t('home.colorShift.saveMoneyAlways'),
      subtitle: t('home.colorShift.noMatterWhere'),
      id: HOMEPAGE_SECTION_ID.ALWAYS,
      animation: FIREBASE_STORAGE_FILE_PATH.HOME_ALWAYS,
      color: HOMEPAGE_BG_COLOR.LIGHT
    },
    {
      navLabel: t('home.colorShift.sections.automatic'),
      title: t('home.colorShift.inRealTime'),
      subtitle: t('home.colorShift.weDontMakeYouWait'),
      id: HOMEPAGE_SECTION_ID.AUTOMATIC,
      large: true,
      animation: FIREBASE_STORAGE_FILE_PATH.HOME_AUTOMATIC,
      color: HOMEPAGE_BG_COLOR.DARK
    },
    {
      navLabel: t('home.colorShift.sections.free'),
      title: t('home.colorShift.noCostNoEffort'),
      subtitle: t('home.colorShift.createFreePoinzAccount'),
      id: HOMEPAGE_SECTION_ID.FREE,
      animation: FIREBASE_STORAGE_FILE_PATH.HOME_FREE,
      color: HOMEPAGE_BG_COLOR.LIGHT
    },
    {
      navLabel: t('home.colorShift.sections.cashPayout'),
      title: t('home.colorShift.swissFrancsStraight'),
      subtitle: t('home.colorShift.whenWeSayCashback'),
      id: HOMEPAGE_SECTION_ID.CASH_PAYOUT,
      animation: FIREBASE_STORAGE_FILE_PATH.HOME_CASHPAYOUT,
      color: HOMEPAGE_BG_COLOR.DARK
    }
  ];
};
