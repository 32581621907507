import firebaseApp from './index';

const ANIMATION_PATH_PREFIX = '/animations/web/';
const FILE_PATH_PREFIX = '/files/';

export enum FIREBASE_STORAGE_FILE_PATH {
  HOME_ALWAYS = `${ANIMATION_PATH_PREFIX}home_always.json`,
  HOME_AUTOMATIC = `${ANIMATION_PATH_PREFIX}home_automatic.json`,
  HOME_FREE = `${ANIMATION_PATH_PREFIX}home_free.json`,
  HOME_CASHPAYOUT = `${ANIMATION_PATH_PREFIX}home_cashpayout.json`,

  COMMON_PASSWORDS = `${FILE_PATH_PREFIX}10-million-password-list-top-10000.txt`
}

const getDownloadUrl = async (filePath: FIREBASE_STORAGE_FILE_PATH) => {
  const getStorage = (await import('firebase/storage')).getStorage;

  const ref = (await import('firebase/storage')).ref;
  const getDownloadURLFirebase = (await import('firebase/storage')).getDownloadURL;

  const storage = getStorage(firebaseApp);
  return await getDownloadURLFirebase(ref(storage, filePath));
};

export const getAnimationFromFirebase = async (animationPath: FIREBASE_STORAGE_FILE_PATH) => {
  try {
    const url = await getDownloadUrl(animationPath);
    const response = await fetch(url);
    return await response.json();
  } catch (e) {
    console.error(`Can't get animation ${animationPath}`);
    throw e;
  }
};

export const getFileFromFirebase = async (filePath: FIREBASE_STORAGE_FILE_PATH) => {
  try {
    const url = await getDownloadUrl(filePath);
    const response = await fetch(url);
    return await response.text();
  } catch (e) {
    console.error(`Can't get file ${filePath}`);
    throw e;
  }
};
