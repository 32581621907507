import * as React from 'react';
import { useHomeColorShiftStaticSections } from './HomeColorShiftStaticSections.utils';
import HomeColorShiftSection from './HomeColorShiftSection';
import { useAppContext } from 'contexts/app';
import clsx from 'clsx';
import { HOMEPAGE_BG_COLOR } from 'contexts/app/store';
import HomeStickyNavigation from './HomeStickyNavigation';

const HomeColorShiftStaticSections: React.FC = () => {
  const sections = useHomeColorShiftStaticSections();
  const { homepageActiveSection } = useAppContext();

  return (
    <div
      className={clsx(
        'HomeColorShiftStaticSections transition-all duration-500 ease-in-out relative',
        {
          ['bg-white']: homepageActiveSection.color === HOMEPAGE_BG_COLOR.LIGHT,
          ['bg-neutral-900']: homepageActiveSection.color === HOMEPAGE_BG_COLOR.DARK
        }
      )}
    >
      {sections.map((section, i) => (
        <HomeColorShiftSection
          key={`home-section-${section.id}`}
          {...section}
          className={clsx({
            ['pb-20']: sections.length - 1 === i
          })}
        />
      ))}
      <div className="absolute top-20 py-32 h-full hidden md:block">
        <HomeStickyNavigation />
      </div>
    </div>
  );
};

export default HomeColorShiftStaticSections;
